<template>
    <div class='product-list'>
        <div class="table-header">
            <categorySearch :source="filterSource" class="searchBlock" @change="initTableData" />
            <a-button type="primary" v-if="userStore.isPlatformAdmin" @click="handleAdd">新增</a-button>
        </div>
        <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
            <template #slotId="{ row }">
                <router-link :to="{
                    path: `/IoTManage/productCenter/productDetail/${row.id}/1`,
                    state: { tabName: row.name },
                }">{{ row.bizId }}</router-link>
            </template>
            <template #slotIsReal="{ row }">
                <span>{{ row.isReal ? '是' : '否' }}</span>
            </template>
            <template #slotIsLongOnline="{ row }">
                <span>{{ row.isLongOnline ? '是' : '否' }}</span>
            </template>
        </basic-table>
        <a-modal v-model:visible="visible" :title="`${isEditing ? '修改' : '添加'}产品`" @ok="ok" @cancel="cancel"
            :confirm-loading="loading">
            <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 6 }">
                <a-form-item name="name" label="产品名称">
                    <a-input v-model:value="formState.name" placeholder="请输入"></a-input>
                </a-form-item>
                <a-form-item name="categoryId" label="所属品类">
                    <a-tree-select v-model:value="formState.categoryId" show-search style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" allow-clear
                        tree-default-expand-all :tree-data="categoryOptions"
                        :field-names="{ children: 'children', label: 'name', value: 'id' }" tree-node-filter-prop="name">
                    </a-tree-select>
                </a-form-item>
                <a-form-item name="isReal" label="实体设备">
                    <a-radio-group v-model:value="formState.isReal">
                        <a-radio :value="true">是</a-radio>
                        <a-radio :value="false">否</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item name="isLongOnline" label="长联设备">
                    <a-radio-group v-model:value="formState.isLongOnline">
                        <a-radio :value="true">是</a-radio>
                        <a-radio :value="false">否</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item name="communicationType" label="通讯方式">
                    <a-select v-model:value="formState.communicationType" @change="handleAdminChange" placeholder="请选择">
                        <a-select-option v-for="item in communicationOptions" :key="item.id" :value="item.value">{{
                            item.label
                        }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item name="timeout" label="通讯超时时间">
                    <a-input v-model:value="formState.timeout" placeholder="请输入" addon-after="秒"></a-input>
                </a-form-item>
                <a-form-item name="factory" label="品牌厂商">
                    <a-input v-model:value="formState.factory" placeholder="请输入"></a-input>
                </a-form-item>
                <a-form-item name="model" label="产品型号">
                    <a-input v-model:value="formState.model" placeholder="请输入"></a-input>
                </a-form-item>
                <a-form-item name="description" label="产品简介">
                    <a-textarea v-model:value="formState.description" placeholder="请输入"></a-textarea>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script setup>
import { BasicTable } from '@/components/basic/table'
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { reactive, ref, onMounted, defineProps, watch, nextTick, defineEmits } from 'vue';
import { Modal, message } from 'ant-design-vue';
import { apiProductLibrary, apiCategoryManageDir } from "@/api/IoT/productCenter.js";
import { apiDict } from '@/api/dict';
import { useUserStore } from "@/store/modules/user";
const userStore = useUserStore();

const props = defineProps({
    tab: String,
    activeTreeNode: Object
})

const emit = defineEmits(['updateTree'])

const isEditing = ref(false)
const visible = ref(false)
const loading = ref(false)
const formState = reactive({
    name: null,
    categoryId: null,
    model: undefined,
    factory: undefined,
    isReal: undefined,
    communicationType: undefined,
    isLongOnline: undefined,
    timeout: undefined,
    description: undefined,
})
const formRef = ref()
const rulesRef = reactive({
    name: [{ required: true, message: '请输入产品名称' }],
    categoryId: [{ required: true, message: '请选择所属品类' }],
    model: [{ required: true, message: '请输入产品型号' }],
    isReal: [{ required: true, message: '请选择设备是否为虚拟设备' }],
    communicationType: [{ required: true, message: '请选择通讯方式' }],
    isLongOnline: [{ required: true, message: '请选择设备是否为长联设备' }],
    timeout: [{ required: true, message: '请输入超时时间' }],
});

const filterSource = [
    {
        label: '产品名称',
        type: 'input',
        bindKey: 'name',
        placeholder: '请输入'
    }
]
const tableConfig = reactive({
    showIndex: false,
    showSelect: false,
    pagination: true,
    isPageBottom: true,
    remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
    columns: [
        {
            title: "ID",
            type: "customize",
            slotName: "slotId",
        },
        {
            title: "产品名称",
            type: "text",
            key: "name",
        },
        {
            title: "所属品类",
            type: "text",
            key: "categoryName",
        },
        {
            title: "产品状态",
            type: "text",
            key: "statusLabel",
        },
        {
            title: "实体设备",
            type: "customize",
            slotName: "slotIsReal"
        },
        {
            title: "长连设备",
            type: "customize",
            slotName: "slotIsLongOnline"
        },
        {
            title: "通讯方式",
            type: "text",
            key: "communicationTypeLabel",
        },
        {
            title: "通讯超时时间（秒）",
            type: "text",
            key: "timeout",
        },
    ],
    action: {
        title: "操作",
        fixed: "right",
        items: [
            {
                icon: "DeliveredProcedureOutlined",
                iconTip: '使用',
                type: "icon",
                onClick: (row) => {
                    handleDeliver(row);
                },
                visibleFn: (row) => userStore.isTenantAdmin && row.status === 0
            },
            {
                icon: "FormOutlined",
                iconTip: '编辑',
                type: "icon",
                onClick: (row) => {
                    handleEdit(row);
                },
                visibleFn: () => userStore.isPlatformAdmin
            },
            {
                icon: "DeleteOutlined",
                iconTip: '删除',
                type: "icon",
                onClick: (row) => {
                    Modal.confirm({
                        title: '提醒',
                        content: '请确认是否删除？',
                        onOk() {
                            return new Promise((resolve) => {
                                apiProductLibrary.delete(row.id).then(() => {
                                    message.success('删除成功');
                                    emit('updateTree')
                                }).finally(() => {
                                    resolve()
                                })
                            })
                        }
                    });
                },
                visibleFn: () => userStore.isPlatformAdmin
            },
        ],
    },
})
const tableRef = ref();
const communicationOptions = ref([])
const categoryOptions = ref([])


watch(() => props.activeTreeNode, () => {
    initTableData()
})
watch(() => props.tab, () => {
    initTableData()
})

onMounted(() => {
    apiDict.selective('PRODUCT_COMMUNICATION_TYPE').then(({ result }) => {
        communicationOptions.value = result
    })
})

const getCategoryDir = () => {
    apiCategoryManageDir.list().then(({ result }) => {
        let arr = result[0]?.children || []
        genDisabled(arr)
        categoryOptions.value = arr
    })
}
const genDisabled = (arr) => {
    arr.forEach(e => {
        if (e.children.length) {
            genDisabled(e.children)
        }
        e.disabled = e.isCatalogue
    })

}

const initTableData = (searchItems = {}) => {
    apiProductLibrary.list({
        pageNo: tableRef.value.paginationProps.current,
        pageSize: tableRef.value.paginationProps.pageSize,
        categoryId: props.activeTreeNode.id,
        isCatalogue: props.activeTreeNode.isCatalogue,
        status: props.tab == -1 ? '' : props.tab,
        ...searchItems
    }).then(({ result }) => {
        tableRef.value.dataSourceRef = result.records || [];
        tableRef.value.paginationProps.total = result.total;
    })
};


const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex
    initTableData()
}

const handleAdd = () => {
    visible.value = true
    isEditing.value = false
    getCategoryDir()
}
const handleEdit = (row) => {
    visible.value = true
    isEditing.value = true
    getCategoryDir()
    nextTick(() => {
        for (const key in row) {
            formState[key] = row[key]
        }
    })
}
const handleDeliver = (row) => {
    Modal.confirm({
        title: '提醒',
        content: '请确认是否引用该产品？',
        onOk() {
            return new Promise((resolve) => {
                apiProductLibrary.deliver(row.id).then(() => {
                    Modal.success({
                        content: '该产品已经从产品库导入到企业中的产品管理列表，请前往产品管理功能查看！',
                        okText: '关闭'
                    });
                    initTableData()
                }).finally(() => {
                    resolve()
                })
            })
        }
    });
}

const ok = () => {
    formRef.value.validateFields().then(() => {
        loading.value = true
        let func = isEditing.value ? 'edit' : 'add'
        apiProductLibrary[func](formState).then(() => {
            message.success(isEditing.value ? '修改成功' : '添加成功')
            cancel()
            emit('updateTree')
        }).finally(() => {
            loading.value = false
        })
    })
}
const cancel = () => {
    visible.value = false
    formRef.value.resetFields();
}

</script>
<style lang='less' scoped>
.table-header {
    display: flex;
    justify-content: space-between;
}
</style>