<template>
    <div class="common-wrapper" id="commonwrapper">
        <aside class="aside">
            <header class="aside-header">
                <bars-outlined />
                <div>品类树</div>
            </header>
            <div class="aside-content">
                <!-- <div class="aside-content-all">全部</div> -->
                <div class="aside-content-list">
                    <a-tree v-if="treeData.length" show-icon :tree-data="treeData" default-expand-all
                        v-model:selectedKeys="selectedKeys" :fieldNames="{ key: 'id', children: 'children', title: 'name' }"
                        @select="selectTreeNode">
                        <template #icon="{ dataRef, expanded }">
                            <auth-image v-if="!dataRef.isCatalogue" :src="dataRef.image" size="18px" />
                            <img v-else-if="expanded" src="../../../../assets/folder-open.png" width="18" />
                            <img v-else src="../../../../assets/folder.png" width="18" />
                        </template>
                    </a-tree>
                </div>
            </div>
        </aside>
        <main class="content">
            <a-tabs v-model:activeKey="activeKey">
                <a-tab-pane key="-1" tab="全部"></a-tab-pane>
                <a-tab-pane key="0" tab="启用"></a-tab-pane>
                <a-tab-pane key="1" tab="停用"></a-tab-pane>
            </a-tabs>
            <ProductList :tab="activeKey" :activeTreeNode='activeTreeNode' @updateTree="initTree(false)" />
        </main>
    </div>
</template>
  
<script setup>
import { BarsOutlined } from "@ant-design/icons-vue";
import { ref, onMounted } from 'vue';
import ProductList from "./component/productList.vue";
import { apiProductLibrary } from "@/api/IoT/productCenter.js";
import authImage from '@/components/basic/authImage/index.vue';

const treeData = ref([]);
const activeTreeNode = ref();
const selectedKeys = ref([]);
const activeKey = ref('-1');

onMounted(() => {
    initTree()
})

const initTree = (resetSelectKeys) => {
    apiProductLibrary.tree().then(({ result }) => {
        treeData.value = result
        activeTreeNode.value = {
            id: result[0].id,
            isCatalogue: result[0].isCatalogue
        }
        if (resetSelectKeys) {
            selectedKeys.value = [result[0].id]
            selectTreeNode(result[0].id, { node: result[0] })
        }
    })
}
const selectTreeNode = (keys, { node }) => {
    activeTreeNode.value = {
        id: node.id,
        isCatalogue: node.isCatalogue
    }
}




</script>
<style lang='less' scoped>
.common-wrapper {
    display: flex;
    background: none;
    padding: 0;
}

.aside {
    position: relative;
    margin-right: 8px;
    width: 240px;
    min-width: 240px;
    background-color: #fff;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 46px;
        padding: 0px 16px;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    &-content {
        padding: 11px 8px;

        &-all {
            padding: 0 4px;
            height: 24px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.content {
    padding: 0 20px;
    background-color: #fff;
    flex: 1;
}
</style>